.stats-page-index-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    background-color: #fafafa;
    max-width: 600px;
}

.stats-page-index-table tr {
    padding: 5px 0px 5px 0px;
    border-top: 1px solid rgba(224, 224, 224, 1);
}

.stats-page-index-table td, .stats-page-index-table th {
    text-align: left;
    padding: 10px 10px 10px 10px;
    font-size: 12px;
}

.stats-page-index-table tbody td:first-of-type {
    font-weight: bold;
}

.stats-page-index-table thead th {
    text-align: left;
    padding: 10px 10px 10px 10px;
}