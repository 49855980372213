[class*="amplify"] {
    all: unset;
    display: unset;
    align-items: unset;
    box-sizing: unset;
    box-sizing: border-box;
}

.amplify-flex {
    align-content: normal;
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: normal;
}

[data-amplify-authenticator] {
    display: grid;
}

[data-amplify-authenticator][data-variation=modal] {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    /* Having a z-index at least "wins" by default */
    z-index: 1;
}

[data-amplify-authenticator] [data-amplify-container] {
    place-self: center;
}
@media (min-width: 30rem) {
    [data-amplify-authenticator] [data-amplify-container] {
        width: 30rem;
    }
}

[data-amplify-authenticator] [data-amplify-router] {
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0px 2px 6px hsla(210, 50%, 10%, 0.15);
    border-style: none;
}

.amplify-tabs[data-indicator-position="top"] {
    border-width: 2px 0 0;
}

.amplify-tabs {
    background-color: initial;
    border-color: #dcdee0;
    border-style: solid;
    border-width: 0 0 2px;
    gap: 0;
}

.amplify-tabs-item[data-spacing="equal"] {
    flex: 1 1;
}

.amplify-tabs-item[data-state="active"] {
    background-color: initial;
    border-color: #047d95;
    color: #047d95;
    transition-property: none;
}

[data-indicator-position="top"] > .amplify-tabs-item {
    border-width: 2px 0 0;
    margin-bottom: 0;
    margin-top: -2px;
}

.amplify-tabs-item {
    background-color: initial;
    border-color: #dcdee0;
    border-style: solid;
    border-width: 0 0 2px;
    box-sizing: border-box;
    color: #304050;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: -2px;
    padding: .75rem 1rem;
    text-align: center;
    transition: all .25s;
}

.amplify-tabs-item:hover {
    color: #056;
    cursor: pointer;
}

[data-amplify-authenticator] [data-state="inactive"] {
    background-color: #fafafa;
}

[data-amplify-authenticator] [data-amplify-form] {
    padding: 2rem;
    border-style: none;
}

.amplify-visually-hidden {
    fill: transparent;
    clip: rect(0,0,0,0);
    border-width: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

fieldset.amplify-flex {
    border-style: none;
}

.amplify-textfield {
    --amplify-components-fieldcontrol-color: hsl(210, 50%, 10%);
    --amplify-components-fieldcontrol-border-color: hsl(210, 10%, 58%);
    --amplify-components-fieldcontrol-font-size: 1rem;
    --amplify-components-fieldcontrol-focus-border-color: hsl(190, 100%, 15%);
}

.amplify-field {
    flex-direction: column;
    font-size: 1rem;
    gap: .5rem;
}

.amplify-label {
    color: #304050;
}

.amplify-field-group--horizontal {
    flex-direction: row;
}

.amplify-field-group {
    align-self: stretch;
    gap: 0;
}

.amplify-field-group__field-wrapper {
    position: relative;
    width: 100%;
}

.amplify-input {
    -webkit-padding-before: .5rem;
    -webkit-padding-after: .5rem;
    -webkit-padding-start: 1rem;
    -webkit-padding-end: 1rem;
    border-color: #89949f;
    border-radius: .25rem;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: #0d1a26;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 2px;
    padding-block-end: .5rem;
    padding-block-start: .5rem;
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
    transition: all .25s;
    -webkit-user-select: text;
    user-select: text;
    width: 100%;
}
.amplify-input:focus {
    border-color: hsl(190, 100%, 15%);
    box-shadow: 0px 0px 0px 1px hsl(190, 100%, 15%);
}

.amplify-field-group__outer-end, .amplify-field-group__outer-start {
    align-items: center;
    display: flex;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
    border-inline-start-color: transparent;
}
.amplify-field-group__outer-end .amplify-field-group__control, .amplify-field-group__outer-start .amplify-field-group__control {
    height: 100%;
}
.amplify-field-group :not(:last-child) .amplify-input, .amplify-field-group__outer-end .amplify-field-group__control:not(:last-child), .amplify-field-group__outer-end .amplify-select__wrapper:not(:last-child) .amplify-select, .amplify-field-group__outer-end--quiet .amplify-field-group__control, .amplify-field-group__outer-start .amplify-field-group__control, .amplify-field-group__outer-start .amplify-select__wrapper .amplify-select {
    border-end-end-radius: 0;
    border-start-end-radius: 0;
}

.amplify-icon {
    fill: currentColor;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
}

.amplify-icon > svg {
    width: 1em;
}
.amplify-icon, .amplify-icon > svg {
    height: 1em;
}

.amplify-field-group :not(:first-child) .amplify-input, .amplify-field-group__outer-end .amplify-field-group__control, .amplify-field-group__outer-end .amplify-select__wrapper .amplify-select, .amplify-field-group__outer-start .amplify-field-group__control:not(:first-child), .amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child), .amplify-field-group__outer-start--quiet .amplify-field-group__control {
    border-end-start-radius: 0;
    border-start-start-radius: 0;
}

.amplify-button {
    -webkit-padding-before: .5rem;
    -webkit-padding-after: .5rem;
    -webkit-padding-start: 1rem;
    -webkit-padding-end: 1rem;
    align-items: center;
    border-color: #89949f;
    border-radius: .25rem;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: #0d1a26;
    cursor: pointer;
    display: inline-flex;
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.5;
    padding-block-end: .5rem;
    padding-block-start: .5rem;
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
    transition: all .25s;
    -webkit-user-select: none;
    user-select: none;
}
.amplify-button:hover {
    background-color: hsl(190, 75%, 95%);
    border-color: hsl(190, 50%, 50%);
    color: hsl(190, 100%, 15%);
}
.amplify-button:focus {
    background-color: hsl(190, 75%, 95%);
    border-color: hsl(190, 100%, 15%);
    color: hsl(190, 100%, 15%);
    box-shadow: 0px 0px 0px 1px hsl(190, 100%, 15%);
}
.amplify-button:active {
    background-color: hsl(190, 75%, 85%);
    border-color: hsl(190, 100%, 15%);
    color: hsl(190, 100%, 15%);
}
.amplify-button--primary:hover {
    background-color: #056;
    border-color: transparent;
    color: #fff;
}
.amplify-button--primary {
    background-color: #047d95;
    border-color: transparent;
    border-width: 1px;
    color: #fff;
}

[data-amplify-authenticator] [data-amplify-footer] {
    padding-bottom: 1rem;
    text-align: center;
}

.amplify-button--small {
    -webkit-padding-before: .375rem;
    -webkit-padding-after: .375rem;
    -webkit-padding-start: .75rem;
    -webkit-padding-end: .75rem;
    font-size: .875rem;
    padding-block-end: .375rem;
    padding-block-start: .375rem;
    padding-inline-end: .75rem;
    padding-inline-start: .75rem;
}
.amplify-button--link {
    background-color: initial;
    border-width: 0;
    color: #047d95;
}

.amplify-button--loading {
    background-color: transparent;
    border-color: hsl(210, 5%, 94%);
    color: hsl(210, 10%, 58%);
    -webkit-text-decoration: initial;
    text-decoration: initial;
}
.amplify-button--loading:hover {
    background-color: transparent;
    border-color: hsl(210, 5%, 94%);
    color: hsl(210, 10%, 58%);
    -webkit-text-decoration: initial;
    text-decoration: initial;
}
.amplify-button--loading:focus {
    background-color: transparent;
    border-color: hsl(210, 5%, 94%);
    color: hsl(210, 10%, 58%);
    -webkit-text-decoration: initial;
    text-decoration: initial;
}
.amplify-button--loading:active {
    background-color: transparent;
    border-color: hsl(210, 5%, 94%);
    color: hsl(210, 10%, 58%);
    -webkit-text-decoration: initial;
    text-decoration: initial;
}
.amplify-button__loader-wrapper {
    align-items: center;
    gap: 0.5rem;
}
@media (prefers-reduced-motion: reduce) {
    .amplify-button {
        transition: none;
    }
}


/* AMPLIFY LOADER */
.amplify-loader {
    width: 1rem;
    height: 1rem;
    font-size: 0.75rem;
    fill: none;
    stroke: hsl(210, 5%, 94%);
  }
  .amplify-loader circle:last-of-type {
    transform-origin: center center;
    animation-name: amplify-loader-circular;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    stroke: hsl(190, 95%, 30%);
    stroke-linecap: round;
  }
  @media (prefers-reduced-motion) {
    .amplify-loader circle:last-of-type {
      animation: none;
      stroke-dasharray: 100% 200%;
      stroke-dashoffset: 50%;
    }
  }
  .amplify-loader line:last-of-type {
    stroke: hsl(190, 95%, 30%);
    stroke-dasharray: 50% 200%;
    animation-name: amplify-loader-linear;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @media (prefers-reduced-motion) {
    .amplify-loader line:last-of-type {
      animation: none;
      stroke-dashoffset: -50%;
    }
  }
  .amplify-loader--small {
    width: 0.875rem;
    height: 0.875rem;
    font-size: 0.5rem;
    /* --amplify-internal-loader-linear-stroke-width: var(
      --amplify-components-loader-linear-small-stroke-width
    );
    --amplify-internal-loader-linear-font-size: var(
      --amplify-components-loader-linear-small-font-size
    ); */
  }
  .amplify-loader--large {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.875rem;
    /* --amplify-internal-loader-linear-stroke-width: var(
      --amplify-components-loader-linear-large-stroke-width
    );
    --amplify-internal-loader-linear-font-size: var(
      --amplify-components-loader-linear-large-font-size
    ); */
  }
  .amplify-loader--linear {
    width: 100%;
    min-width: 5rem;
    stroke: hsl(210, 5%, 94%);
    stroke-linecap: round;
    stroke-width: 1rem;
    font-size: 1rem;
  }
  .amplify-loader--determinate circle:last-of-type {
    animation: none;
    transform: rotate(-90deg);
  }
  .amplify-loader--determinate line:last-of-type {
    animation: none;
    stroke-dashoffset: 0%;
    stroke-dasharray: none;
  }
  .amplify-loader__percentage-text {
    fill: hsl(210, 50%, 10%);
    stroke: none;
  }
  
  @keyframes amplify-loader-circular {
    0% {
      stroke-dasharray: 100% 200%;
      transform: rotate(120deg);
    }
    50% {
      stroke-dasharray: 20% 400%;
    }
    100% {
      stroke-dasharray: 100% 200%;
      transform: rotate(480deg);
    }
  }
  @keyframes amplify-loader-linear {
    0% {
      stroke-dashoffset: 200%;
    }
    100% {
      stroke-dashoffset: -50%;
    }
  }
  .amplify-placeholder {
    animation-direction: alternate;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-name: amplify-placeholder-loading;
    border-radius: 0.25rem;
    height: 1rem;
    width: 100%;
    display: block;
  }
  .amplify-placeholder--small {
    height: 0.75rem;
  }
  .amplify-placeholder--large {
    height: 1.5rem;
  }
  
  @keyframes amplify-placeholder-loading {
    0% {
      background-color: hsl(210, 5%, 98%);
    }
    100% {
      background-color: hsl(210, 5%, 94%);
    }
  }
  @media (prefers-reduced-motion: reduce) {
    .amplify-placeholder {
      animation: none;
      background-color: hsl(210, 5%, 94%);
    }
  }