@media (max-width: 900px) {
    #main-theme-div {
        border-radius: 0;
        margin-right: 0;
        padding: 0;
    }
}

.main-mobile {
    border-radius: 0;
}

@media only screen and (max-width: 500px) {
    .footer-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}