.navigation-tabs {
    display: flex;
}

.navigation-tab-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 12px 16px 12px 16px;
    min-width: 90px;
}

.navigation-tab-box a {
    font-family: 'Roboto',sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: #9e9e9e;
    text-decoration: none;
}

.header-navigation-link {
    font-family: 'Roboto',sans-serif;
    font-weight: 500;
    /* font-weight: bold; */
    font-size: 0.875rem;
    /* color: #9e9e9e; */
    color: #fafafa;
    text-decoration: none;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px 10px;
    margin-left: 20px;
    font-size: 13px;
    box-shadow: 0px 2px 0px 0px #9e9e9e;
}
.header-navigation-link:hover {
    box-shadow: 0px 2px 0px 0px #2196f3;
    /* color: #2196f3; */
}
.header-navigation-link p {
    margin: 3px 0px 3px 0px;
}

/* .logo-box-header:hover p{
    color: #2196f3;
} */


.navigation-tab-box[is-selected='true'] {
    box-shadow: 0px 3px 0px 0px #2196f3
}
.navigation-tab-box[is-selected='true'] a {
    color: #2196f3;
}

.header-slogan {
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* color: #fafafa; */
    color: #2196f3;
}
.header-slogan p {
    margin: 0;
    font-size: 10px;
    font-weight: 500;
}